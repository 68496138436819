import { URL_ABOUT_US, URL_CHANGE_PWD, URL_PRIVACY_POLICY, URL_PROFILE, URL_TERMS_AND_CONDITIONS, URL_CONTACT_US, URL_DATA_SECURITY } from "Helpers";
//IMAGES
import HistoryRoundImage from "Assets/Images/GlobalLandingPage/history-round-image.png";
import EnglishRoundImage from "Assets/Images/GlobalLandingPage/english-round-image.png";
import MathRoundImage from "Assets/Images/GlobalLandingPage/math-round-image.png";
import ScienceRoundImage from "Assets/Images/GlobalLandingPage/science-round-image.png";
import EconomicsRoundImage from "Assets/Images/GlobalLandingPage/economics-round-image.png";
import ForeignRoundImage from "Assets/Images/GlobalLandingPage/foreign-round-image.png";
import Twitter from "Assets/Images/GlobalLandingPage/twitter.png";
import Instagram from "Assets/Images/GlobalLandingPage/instagram.png";
import Facebook from "Assets/Images/GlobalLandingPage/facebook.png";
import Star from "Assets/Images/Background/star.png";
import LeftStar from "Assets/Images/Background/left-star.png";
import BlueFeather from "Assets/Images/Background/blue-feather.png";
import GreenRibbon from "Assets/Images/Background/green-ribbon.png";
import YellowFeather from "Assets/Images/Background/yellow-feather.png";
import BlueBall from "Assets/Images/Background/blue-ball.png";
import GreenBall from "Assets/Images/Background/green-ball.png";
import RedRibbon from "Assets/Images/GlobalLandingPage/red-ribbon.png";
import BlueRibbon from "Assets/Images/GlobalLandingPage/blue-ribbon.png";
import TikTok from "Assets/Images/GlobalLandingPage/tiktok.png";

const baseURL = process.env.REACT_APP_S3_BUCKET_IMAGE_URL;
const baseURLVideo = process.env.REACT_APP_S3_BUCKET_VIDEO_URL;

export const FOOTER_MODULE = [
	{
		id: 1,
		title: "ABOUT US",
		path: URL_ABOUT_US,
	},
	{
		id: 2,
		title: "TERMS & CONDITIONS",
		path: URL_TERMS_AND_CONDITIONS,
	},
	{
		id: 3,
		title: "PRIVACY POLICY",
		path: URL_PRIVACY_POLICY,
	},
	{
		id: 4,
		title: "DATA SECURITY",
		path: URL_DATA_SECURITY,
	},
	{
		id: 5,
		title: "CONTACT US",
		path: URL_CONTACT_US,
	},
];

export const SOCIAL_MEDIA_MODULE = [
	{
		id: 1,
		title: "TWITTER",
		img: Twitter,
	},
	{
		id: 2,
		title: "FACEBOOK",
		img: Facebook,
	},
	{
		id: 3,
		title: "INSTAGRAM",
		img: Instagram,
	},
	{
		id: 4,
		title: "TIKTOK",
		img: TikTok,
	},
];

export const MODULE_ID = {
	CONSTITUTION: "Constitution",
	JUDICIAL: "Judicial Branch",
	EXECUTIVE: "Executive Branch",
	LEGISLATIVE: "Legislative Branch",
	QUIZ: "Quiz",
	BILL_OR_NO_BILL: "Legislative Branch(Bill No Bill)",
	BILL_BECOMES_LAW: "Legislative Branch(How Bill Becomes Law)",
	FINALS: "Final Game",
};

export const HISTORY_MODULE_ID = {
	RAPID_FIRE: "Rapid Fire History",
	BILL_OR_NO_BILL: "Present Or Past History",
};

export const FOREIGN_MODULE_ID = {
	RAPID_FIRE: "Rapid Fire Foreign Language",
	QUIZ: "Quizardy Foreign Language",
};

export const SCIENCE_MODULE_ID = {
	RAPID_FIRE: "Rapid Fire Science",
	PERIODIC_TABLE: "Periodic Table",
	WEAKEST_LINK: "Loose Link",
};

export const ECONOMICS_MODULE_ID = {
	PURSUIT: "Pursuit",
	RAPID_FIRE: "Rapid Fire Economics",
};

export const ENGLISH_MODULE_ID = {
	CROSSWORD: "Cross Word",
	RAPID_FIRE: "Rapid Fire English",
};

export const MATHS_MODULE_ID = {
	CONNECT4: "Connect 4",
	MATHAHTZEE: "Mathahtzee",
};

export const PRICES_TYPE = {
	SPINS: "SPINS", // EARN POINTS
	POINTS: "POINTS", // EARN POINTS
	HOME: "HOME", // IF CORRECT QUESTION DOUBLE THE POINTS
	GAVEL: "GAVEL", // LOOSE ALL POINTS
};

export const INPUT_TYPE = {
	TEXT: "TEXT",
	SELECT: "SELECT",
};

export const AGE_RANGE = [
	{
		value: "0-12",
		display: "0-12",
	},
	{
		value: "13-19",
		display: "13-19",
	},
	{
		value: "20-30",
		display: "20-30",
	},
	{
		value: "31-45",
		display: "31-45",
	},
	{
		value: "46-60",
		display: "46-60",
	},
	{
		value: "Over 61",
		display: "Over 61",
	},
];

export const MODULES = {
	CONSTITUTION: "Constitution",
	JUDICIAL: "Judicial",
	EXECUTIVE: "Executive",
	LEGISLATIVE: "Legislative",
};

export const QUESTION_TYPES = {
	TEXT_FIELD: 1,
	SINGLE_CHOICE: 2,
	MULTI_CHOICE: 3,
	RANKING_CHOICE: 4,
};

export const QUESTION_FORMATS = {
	TEXT: "TEXT",
	IMAGE: "IMAGE",
	VIDEO: "VIDEO",
};

export const SOCIAL_MEDIA_LINKS = {
	TWITTER: "https://twitter.com/subjectsmaster",
	FACEBOOK: "https://www.facebook.com/subjectsmaster?mibextid=LQQJ4d",
	INSTAGRAM: "https://www.instagram.com/subjectsmaster?igsh=OGQ5ZDc2ODk2ZA==",
	TIKTOK: "https://www.tiktok.com/@subjectsmaster?_t=8iVpTGlOxLy&_r=1",
};

export const SUBJECT_NAME = {
	FOREIGN_LANGUAGE: "Foreign_Language",
};

export const QUIZ_GAME_COLORS = {
	PURPLE: "PURPLE",
	BLUE: "BLUE",
	GREEN: "GREEN",
	BROWN: "BROWN",
};

export const commonSidebarListItems = [
	{ text: "Edit Profile", clickParams: URL_PROFILE },
	{ text: "About us", clickParams: URL_ABOUT_US },
	{ text: "Privacy policy", clickParams: URL_PRIVACY_POLICY },
	{ text: "Terms & conditions", clickParams: URL_TERMS_AND_CONDITIONS },
	{ text: "Data Security", clickParams: URL_DATA_SECURITY },
	{ text: "Change Password", clickParams: URL_CHANGE_PWD },
	{ text: "Contact Us", clickParams: URL_CONTACT_US },
];

export const IMAGES = {
	CIVICS: {
		BACKGROUND: baseURL + "Civics/BackgroundImage.webp",
		BACKGROUND_COMPRESSED: baseURL + "Civics/BackgroundImageSmall.webp",
		CIVIC_BACKGROUND: baseURL + "Civics/CivicBackgroundImage.webp",
		CIVIC_BACKGROUND_COMPRESSED: baseURL + "Civics/CivicBackgroundImageCompressed.webp",
		CONSTITUTION_ICON: baseURL + "Civics/constitution-icon.png",
		CONSTITUTION_LOGO: baseURL + "Civics/ConstitutionIcon.png",
		EXECUTIVE_ICON: baseURL + "Civics/ExecutiveIcon.png",
		FINAL_ICON: baseURL + "Civics/FinalIcon.png",
		JUDICIAL_ICON: baseURL + "Civics/JudicialIcon.png",
		LEGISLATIVE_ICON: baseURL + "Civics/LegislativeIcon.png",
		MAN_LOGO: baseURL + "Civics/logo-man.png",
		CIVIC_LOGO: baseURL + "Civics/logo-without-man.png",
		LUCK: baseURL + "Civics/luck.png",
		TOP_BAR_TILE: baseURL + "Civics/my-profile.png",
		QUIZ_ICON: baseURL + "Civics/QuizIcon.png",
		RECTANGLE: baseURL + "Civics/rectangle.png",
		RIBBON: baseURL + "Civics/ribbon.png",
		SETTINGS_ICON: baseURL + "Civics/SettingsIcon.png",
		BILL_OR_NO_BILL: {
			BACKGROUND: baseURL + "Civics/BillOrNoBill/BillNoBillBackgroundImage.webp",
			BACKGROUND_COMPRESSED: baseURL + "Civics/BillOrNoBill/BillNoBillBackgroundImageCompressed.webp",
			OPEN_SCROLL: baseURL + "Civics/BillOrNoBill/open-scroll.png",
			TILE: baseURL + "Civics/BillOrNoBill/tile.png",
			TITLE: baseURL + "Civics/BillOrNoBill/title.png",
		},
		DASHBOARD: {
			CONSTITUTION: baseURL + "Civics/Dashboard/constitution.png",
			EXECUTIVE: baseURL + "Civics/Dashboard/executive-branch.png",
			FINAL: baseURL + "Civics/Dashboard/Final.png",
			JUDICIAL: baseURL + "Civics/Dashboard/judicial-branch.png",
			LEGISLATIVE: baseURL + "Civics/Dashboard/legislative-branch.png",
			QUIZ: baseURL + "Civics/Dashboard/quiz.png",
			SCOREBOARD: baseURL + "Civics/Dashboard/scoreboard-card.png",
		},
		ESCAPE_ROOM: {
			BOOK: baseURL + "Civics/EscapeRoom/book.png",
			CALENDAR: baseURL + "Civics/EscapeRoom/calendar.png",
			CLUE1: baseURL + "Civics/EscapeRoom/clue1.png",
			CLUE2: baseURL + "Civics/EscapeRoom/clue2.jpg",
			CLUE_BUTTON: baseURL + "Civics/EscapeRoom/clue_button.png",
			KEY: baseURL + "Civics/EscapeRoom/key.png",
			PAD_LOCK: baseURL + "Civics/EscapeRoom/lock.png",
			LOCK: baseURL + "Civics/EscapeRoom/lock2.png",
			NOTE: baseURL + "Civics/EscapeRoom/Note.jpg",
			ORIGINAL_OVAL: baseURL + "Civics/EscapeRoom/original_oval.jpg",
			ORIGINAL_OVAL_EDIT: baseURL + "Civics/EscapeRoom/original_oval_edit.jpg",
			PRESIDENT: baseURL + "Civics/EscapeRoom/presidents.jpg",
			PRESIDENT1: baseURL + "Civics/EscapeRoom/presidents1.png",
			TRANSPARENT: baseURL + "Civics/EscapeRoom/transparent.png",
		},
		QUIZARDY: {
			BACKGROUND: baseURL + "Civics/JeoPardy/bg.png",
			BACKGROUND_COMPRESSED: baseURL + "Civics/JeoPardy/bgCompressed.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "Civics/JeoPardy/CongratulationsBackground.png",
			CONSTITUTION: baseURL + "Civics/JeoPardy/constitution-bg.png",
			CONSTITUTION_COMPRESSED: baseURL + "Civics/JeoPardy/constitutionBgCompressed.png",
			CONSTITUTION_SHADOW: baseURL + "Civics/JeoPardy/constitution-shadow.png",
			CONTENT: baseURL + "Civics/JeoPardy/content.png",
			DAILY_DOUBLE_BACKGROUND: baseURL + "Civics/JeoPardy/DailyDoubleBackground.png",
			DAILY_DOUBLE_FRONT: baseURL + "Civics/JeoPardy/DailyDoubleFrontImage.png",
			EXECUTIVE: baseURL + "Civics/JeoPardy/executive-bg.png",
			EXECUTIVE_COMPRESSED: baseURL + "Civics/JeoPardy/executiveBgCompressed.png",
			EXECUTIVE_SHADOW: baseURL + "Civics/JeoPardy/executive-shadow.png",
			JUDICIAL: baseURL + "Civics/JeoPardy/judicial-bg.png",
			JUDICIAL_COMPRESSED: baseURL + "Civics/JeoPardy/judicialBgCompressed.png",
			JUDICIAL_SHADOW: baseURL + "Civics/JeoPardy/judicial-shadow.png",
			LADY: baseURL + "Civics/JeoPardy/lady.png",
			LEGISLATIVE: baseURL + "Civics/JeoPardy/legislative-bg.png",
			LEGISLATIVE_COMPRESSED: baseURL + "Civics/JeoPardy/legislativeBgCompressed.png",
			LEGISLATIVE_SHADOW: baseURL + "Civics/JeoPardy/legislative-shadow.png",
			QUIZARDY_2: baseURL + "Civics/JeoPardy/Quizardy-2.png",
			TABLE: baseURL + "Civics/JeoPardy/TableImage.png",
			TEXT: baseURL + "Civics/JeoPardy/text-image.png",
			TOP: baseURL + "Civics/JeoPardy/top.png",
		},
		JUDICIAL: {
			BADGE: baseURL + "Civics/Judicial/badge.png",
			CROSS: baseURL + "Civics/Judicial/cross.png",
			ICON: baseURL + "Civics/Judicial/icon.png",
			SINKING: baseURL + "Civics/Judicial/sinking.svg",
			SPIN_BACKGROUND: baseURL + "Civics/Judicial/SpinBackgroundImage.webp",
			SPIN_BACKGROUND_COMPRESSED: baseURL + "Civics/Judicial/SpinBackgroundImageCompressed.webp",
			TROPHY: baseURL + "Civics/Judicial/trophy.png",
			SPIN: {
				ONE: baseURL + "Civics/Judicial/Spin/1.png",
				TWO: baseURL + "Civics/Judicial/Spin/2.png",
				THREE: baseURL + "Civics/Judicial/Spin/3.png",
				FOUR: baseURL + "Civics/Judicial/Spin/4.png",
				FIVE: baseURL + "Civics/Judicial/Spin/5.png",
				SIX: baseURL + "Civics/Judicial/Spin/6.png",
				SEVEN: baseURL + "Civics/Judicial/Spin/7.png",
				EIGHT: baseURL + "Civics/Judicial/Spin/8.png",
				GAVEL: baseURL + "Civics/Judicial/Spin/gavel.png",
				HOME: baseURL + "Civics/Judicial/Spin/home.png",
			},
		},
		KBC: {
			BACKGROUND: baseURL + "Civics/Kbc/Background.webp",
			BACKGROUND_COMPRESSED: baseURL + "Civics/Kbc/BackgroundSmall.webp",
			FIFTY: baseURL + "Civics/Kbc/fifty.png",
			FLIP: baseURL + "Civics/Kbc/flip.png",
			GAME_COMPLETE_BACKGROUND: baseURL + "Civics/Kbc/GameCompleteBackground.png",
			DASHBOARD: baseURL + "Civics/Kbc/GoToDashboardButton.png",
			GREEN_BACKGROUND: baseURL + "Civics/Kbc/GreenPointsBackground.png",
			POLL: baseURL + "Civics/Kbc/poll.png",
			USED: baseURL + "Civics/Kbc/used.png",
			WIN: baseURL + "Civics/Kbc/win.png",
		},
		LAWBILL: {
			BILL: baseURL + "Civics/LawBill/bill-law.png",
			FRAMEV1: baseURL + "Civics/LawBill/framev1.png",
			FRAMEV2: baseURL + "Civics/LawBill/framev2.png",
			BACKGROUND: baseURL + "Civics/LawBill/LawBillBackgroundImage.webp",
			BACKGROUND_COMPRESSED: baseURL + "Civics/LawBill/LawBillBackgroundImageCompressed.webp",
			TIMER: baseURL + "Civics/LawBill/timer.png",
			GIFTS: {
				BACKGROUND: baseURL + "Civics/LawBill/Gifts/background.png",
				BAG: baseURL + "Civics/LawBill/Gifts/Bag.png",
				HOODIE: baseURL + "Civics/LawBill/Gifts/Hoodie.png",
				TSHIRT: baseURL + "Civics/LawBill/Gifts/Tshirt.png",
				WINDBREAKER: baseURL + "Civics/LawBill/Gifts/windBreaker.png",
				BAG_WHITE: baseURL + "Civics/LawBill/Gifts/whiteBag.png",
				HOODIE_WHITE: baseURL + "Civics/LawBill/Gifts/whiteHoodie.png",
				TSHIRT_WHITE: baseURL + "Civics/LawBill/Gifts/whiteTshirt.png",
				WINDBREAKER_WHITE: baseURL + "Civics/LawBill/Gifts/Whitewindbreaker.png",
			},
		},
	},
	HISTORY: {
		WHITE_LOGO: baseURL + "History/historylogo.png",
		COMMON_BACKGROUND: baseURL + "History/HistoryBackgroundImage.webp",
		COMPRESSED_COMMON_BACKGROUND: baseURL + "History/HistoryBackgroundImageCompressed.webp",
		FIRE_BALL: baseURL + "History/fireBall.png",
		HOW_TO_PLAY: baseURL + "History/howToPlay.png",
		RAPID_FIRE_CARD: baseURL + "History/RapidFire.png",
		PRESENT_OR_PAST_CARD: baseURL + "History/PresentOrPast.png",
		TOP_BAR_TILE: baseURL + "History/topBarProfile.png",
		LEADERBOARD: baseURL + "History/topList.png",
		RAPID_FIRE: {
			BETTER_LUCK_BACKGROUND: baseURL + "History/RapidFire/BetterLuckBoard.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "History/RapidFire/CongratulationsBoard.png",
			HOME: baseURL + "History/RapidFire/Home.png",
			QUESTION_MODAL: baseURL + "History/RapidFire/questionModal.png",
			SCORE: baseURL + "History/RapidFire/Score.png",
			WRAPPED_UP: baseURL + "History/RapidFire/WrappedUp.png",
		},
		PRESENT_OR_PAST: {
			BACKGROUND: baseURL + "History/PresentOrPast/BackgroundImage.webp",
			BACKGROUND_COMPRESSED: baseURL + "History/PresentOrPast/BackgroundImageCompressed.webp",
			CONGRATULATIONS_BACKGROUND: baseURL + "History/PresentOrPast/CongratulationsModal.png",
			DASHBOARD_BACKGROUND: baseURL + "History/PresentOrPast/DashboardButton.png",
			HOW_TO_PLAY: baseURL + "History/PresentOrPast/HowToPlay.png",
			HOW_TO_PLAY_BUTTON: baseURL + "History/PresentOrPast/HowToPlayButton.png",
			PAST_LOGO: baseURL + "History/PresentOrPast/PastLogo.png",
			PLAY: baseURL + "History/PresentOrPast/play.png",
			POINT_WRONG_TILE: baseURL + "History/PresentOrPast/PointWrongTile.png",
			PRESENT_LOGO: baseURL + "History/PresentOrPast/PresentLogo.png",
			PRESENT_PAST_LOGO: baseURL + "History/PresentOrPast/PresentOrPastLogo.webp",
			PRESENT_PAST_MENU: baseURL + "History/PresentOrPast/PresentOrPastMenu.webp",
			SIDE_LOGO: baseURL + "History/PresentOrPast/PresentOrPastSidebarLogoTiny.png",
			QUESTION_MODAL: baseURL + "History/PresentOrPast/QuestionModal.png",
			RECTANGLE: baseURL + "History/PresentOrPast/Rectangle.png",
			SCOREBOARD: baseURL + "History/PresentOrPast/ScoreBoard.png",
			SIDEBARLOGO: baseURL + "History/PresentOrPast/SidebarLogo.png",
			SUBMIT_BUTTON: baseURL + "History/PresentOrPast/SubmitButton.png",
			TILE: baseURL + "History/PresentOrPast/tile.png",
			TILE2: baseURL + "History/PresentOrPast/tile2.png",
			TITLE: baseURL + "History/PresentOrPast/title.png",
		},
	},
	ENGLISH: {
		WHITE_LOGO: baseURL + "English/Logo.png",
		COMMON_BACKGROUND: baseURL + "English/BackgroundImage.png",
		COMPRESSED_COMMON_BACKGROUND: baseURL + "English/compressedBackgroundImage.png",
		RAPID_FIRE_CARD: baseURL + "English/RapidFire.png",
		CROSSWORD_CARD: baseURL + "English/Crossword.png",
		TOP_BAR_TILE: baseURL + "English/ScoreBoard.png",
		LEADERBOARD: baseURL + "English/LeaderBoard.png",
		RAPID_FIRE: {
			BETTER_LUCK_BACKGROUND: baseURL + "English/RapidFire/BetterLuckBoard.png",
			BACKGROUND_COMPRESSED: baseURL + "English/RapidFire/compressedGamePlayBackground.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "English/RapidFire/CongratulationsBoard.png",
			CORRECT_OPTION: baseURL + "English/RapidFire/CorrectOption.png",
			BACKGROUND: baseURL + "English/RapidFire/GameplayBackground.png",
			HOME: baseURL + "English/RapidFire/Home.png",
			HOW_TO_PLAY: baseURL + "English/RapidFire/HowToPlayBoard.png",
			HOW_TO_PLAY_BUTTON: baseURL + "English/RapidFire/HowToPlayButton.png",
			INCORRECT_OPTION: baseURL + "English/RapidFire/InCorrectOption.png",
			OPTION: baseURL + "English/RapidFire/Option.png",
			QUESTION_MODAL: baseURL + "English/RapidFire/QuestionBoard.png",
			REPLAY: baseURL + "English/RapidFire/Replay.png",
			WRAPPED_UP: baseURL + "English/RapidFire/WrappedUp.png",
		},
		CROSSWORD: {
			LOGO: baseURL + "English/Crossword/CrosswordLogo.png",
			HOME_BACKGROUND: baseURL + "English/Crossword/HomeBckImg.png",
			RULES_BACKGROUND: baseURL + "English/Crossword/RulesBackImg.png",
		},
	},
	SCIENCE: {
		BLACK_LOGO: baseURL + "Science/logo.png",
		WHITE_LOGO: baseURL + "Science/scienceMaster.png",
		COMMON_BACKGROUND: baseURL + "Science/background.png",
		COMPRESSED_COMMON_BACKGROUND: baseURL + "Science/compressedBackground.png",
		FIRE_BALL: baseURL + "Science/fireball.png",
		HOW_TO_PLAY: baseURL + "Science/howToPlay.png",
		RAPID_FIRE_CARD: baseURL + "Science/card.png",
		PERIODIC_TABLE_CARD: baseURL + "Science/PeriodicTable.png",
		LOOSE_LINK_CARD: baseURL + "Science/looselinkLogo.png",
		TOP_BAR_TILE: baseURL + "Science/topBarProfile.png",
		LEADERBOARD: baseURL + "Science/topList.png",
		RAPID_FIRE: {
			BETTER_LUCK_BACKGROUND: baseURL + "Science/RapidFire/BetterLuckBoard.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "Science/RapidFire/CongratulationsBoard.png",
			HOME: baseURL + "Science/RapidFire/Home.png",
			QUESTION_MODAL: baseURL + "Science/RapidFire/questionModal.png",
			REPLAY_BUTTON: baseURL + "Science/RapidFire/ReplayButton.png",
			SCORE: baseURL + "Science/RapidFire/Score.png",
			WRAPPED_UP: baseURL + "Science/RapidFire/WrappedUp.png",
		},
		PERIODIC_TABLE: {
			ACTINIDE: baseURL + "Science/PeriodicTable/actinideFlask.png",
			ACTINIDE_TINY: baseURL + "Science/PeriodicTable/actinideTiny.png",
			ALKALI: baseURL + "Science/PeriodicTable/alkaliEarthFlask.png",
			ALKALI_TINY: baseURL + "Science/PeriodicTable/alkalineEarthTiny.png",
			ALKALINE: baseURL + "Science/PeriodicTable/alkaliMetalFlask.png",
			ALKALINE_TINY: baseURL + "Science/PeriodicTable/alkalineMetalTiny.png",
			BASIC_METAL: baseURL + "Science/PeriodicTable/basicMetalFlask.png",
			BASIC_METAL_TINY: baseURL + "Science/PeriodicTable/basicMetalTiny.png",
			HALOGEN: baseURL + "Science/PeriodicTable/halogenFlask.png",
			HALOGEN_TINY: baseURL + "Science/PeriodicTable/halogenTiny.png",
			LANTHANIDE: baseURL + "Science/PeriodicTable/lanthanideFlask.png",
			LANTHANIDE_TINY: baseURL + "Science/PeriodicTable/lanthanideTiny.png",
			NOBLE_GAS: baseURL + "Science/PeriodicTable/noblegasFlask.png",
			NOBLE_GAS_TINY: baseURL + "Science/PeriodicTable/nobleGasTiny.png",
			NONE_METAL: baseURL + "Science/PeriodicTable/noneMetalFlask.png",
			NONE_METAL_TINY: baseURL + "Science/PeriodicTable/nonemetalTiny.png",
			SEMI_METAL: baseURL + "Science/PeriodicTable/semiMetalFlask.png",
			SEMI_METAL_TINY: baseURL + "Science/PeriodicTable/semimetalTiny.png",
			TRANSITION: baseURL + "Science/PeriodicTable/transitionMetalFlask.png",
			TRANSITION_TINY: baseURL + "Science/PeriodicTable/transitionMetalTiny.png",
			ARROW: baseURL + "Science/PeriodicTable/arrow.png",
			BACKGROUND: baseURL + "Science/PeriodicTable/tableBackground.png",
			BACKGROUND_COMPRESSED: baseURL + "Science/PeriodicTable/compressedTableBackground.png",
			LEFT_ARROW: baseURL + "Science/PeriodicTable/leftArrow.png",
			RIGHT_ARROW: baseURL + "Science/PeriodicTable/rightArrow.png",
			BEAKER: baseURL + "Science/PeriodicTable/periodicTableBeaker.png",
			YELLOW_BEAKER: baseURL + "Science/PeriodicTable/YellowBeaker.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "Science/PeriodicTable/CongratulationsBackground.png",
		},
		LOOSE_LINK: {
			BACKGROUND: baseURL + "Science/WeakestLink/BackgroundPeople.png",
			BACKGROUND_COMPRESSED: baseURL + "Science/WeakestLink/compressedBackgroundPeople.png",
			GAME_BACKGROUND: baseURL + "Science/WeakestLink/linkBackground.png",
			GAME_BACKGROUND_COMPRESSED: baseURL + "Science/WeakestLink/compressedLinkBackground.png",
			AVATAR: baseURL + "Science/WeakestLink/avatar.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "Science/WeakestLink/congratulationModal.png",
			HOW_TO_PLAY: baseURL + "Science/WeakestLink/HowToPlay.png",
			LOGO: baseURL + "Science/WeakestLink/looseLinkLogo.png",
			LOSE_LOGO: baseURL + "Science/WeakestLink/loseIcon.png",
			LOSE_MODAL: baseURL + "Science/WeakestLink/loseModal.png",
			PLAY_BUTTON: baseURL + "Science/WeakestLink/playBtn.png",
			QUESTION_LOGO: baseURL + "Science/WeakestLink/questionLogo.png",
			SCOREBOARD: baseURL + "Science/WeakestLink/scoreBox.png",
			TIMER: baseURL + "Science/WeakestLink/timer.png",
			WIN_LOGO: baseURL + "Science/WeakestLink/winnerBadge.png",
		},
	},
	MATH: {
		BOARD: baseURL + "Maths/Board.webp",
		WHITE_LOGO: baseURL + "Maths/Logo.webp",
		COMMON_BACKGROUND: baseURL + "Maths/RedBackgroundImage.webp",
		COMPRESSED_COMMON_BACKGROUND: baseURL + "Maths/RedBackgroundImageSmall.webp",
		VOLUME: baseURL + "Maths/VolumeImage.webp",
		TOP_BAR_TILE: baseURL + "Maths/ProfileBackground.webp",
		LEADERBOARD: baseURL + "Maths/Scoreboard.webp",
		CONNECT4: {
			AVATAR: baseURL + "Maths/Connect4/Avatar.webp",
			BETTER_LUCK_BACKGROUND: baseURL + "Maths/Connect4/BetterLuckBoard.webp",
			BLUE_PLAYER: baseURL + "Maths/Connect4/BluePlayer.webp",
			BOARD: baseURL + "Maths/Connect4/Board.webp",
			CONGRATULATIONS_BACKGROUND: baseURL + "Maths/Connect4/CongratulationsBoard.webp",
			FINAL_BOARD: baseURL + "Maths/Connect4/FinalBoard.webp",
			GREEN_BACKGROUND: baseURL + "Maths/Connect4/GreenBackground.webp",
			GREEN_BACKGROUND_COMPRESSED: baseURL + "Maths/Connect4/GreenBackgroundSmall.webp",
			LETS_PLAY: baseURL + "Maths/Connect4/LetsPlay.webp",
			MATCH_COMPLETE: baseURL + "Maths/Connect4/MatchCompleteScoreBoard.webp",
			PLAY_LOGO: baseURL + "Maths/Connect4/PlayLogo.webp",
			PLAY_WITH_COMPUTER: baseURL + "Maths/Connect4/PlayWithComputer.webp",
			PLAY_WITH_FRIEND: baseURL + "Maths/Connect4/PlayWithFriends.webp",
			RED_PLAYER: baseURL + "Maths/Connect4/RedPlayer.webp",
			SMALL_BOARD: baseURL + "Maths/Connect4/SmallBoard.webp",
			TOP_BOX: baseURL + "Maths/Connect4/TopBox.webp",
			WRAPPED_UP: baseURL + "Maths/Connect4/WrappedUpBoard.webp",
			YELLOW_PLAYER: baseURL + "Maths/Connect4/YellowPlayer.webp",
		},
		MATHAHTZEE: {
			BLUE_DICE_FIVE: baseURL + "Maths/Mathahtzee/BlueDiceFive.webp",
			BLUE_DICE_ONE: baseURL + "Maths/Mathahtzee/BlueDiceOne.webp",
			BLUE_DICE_SIX: baseURL + "Maths/Mathahtzee/BlueDiceSix.webp",
			BLUE_DICE_THREE: baseURL + "Maths/Mathahtzee/BlueDiceThree.webp",
			BLUE_DICE_TWO: baseURL + "Maths/Mathahtzee/BlueDiceTwo.webp",
			BLUE_SCORE_BACKGROUND: baseURL + "Maths/Mathahtzee/BlueScoreBackground.webp",
			BLUE_USER_BACKGROUND: baseURL + "Maths/Mathahtzee/BlueUserBackground.webp",
			CORRECT_OPTION: baseURL + "Maths/Mathahtzee/CorrectOptionImage.webp",
			CROSS: baseURL + "Maths/Mathahtzee/CrossIcon.webp",
			CROWN: baseURL + "Maths/Mathahtzee/Crown.webp",
			DICE3X: baseURL + "Maths/Mathahtzee/Dice3X.webp",
			DICE4X: baseURL + "Maths/Mathahtzee/Dice4X.webp",
			DICE_CHANGE: baseURL + "Maths/Mathahtzee/DiceChance.webp",
			DICE_HOME: baseURL + "Maths/Mathahtzee/DiceHome.webp",
			DICE_LARGE: baseURL + "Maths/Mathahtzee/DiceLarge.webp",
			DICE_SMALL: baseURL + "Maths/Mathahtzee/DiceSmall.webp",
			CONGRATULATIONS_BACKGROUND: baseURL + "Maths/Mathahtzee/FinalCongratulations.webp",
			GAME_PLAY_BACKGROUND: baseURL + "Maths/Mathahtzee/GamePlayBackgroundPrimary.webp",
			GAME_PLAY_BACKGROUND_COMPRESSED: baseURL + "Maths/Mathahtzee/GamePlayBackgroundPrimarySmall.webp",
			GAME_PLAY_BACKGROUND_SECONDARY: baseURL + "Maths/Mathahtzee/GamePlayBackgroundSecondary.webp",
			GAME_PLAY_BACKGROUND_SECONDARY_COMPRESSED: baseURL + "Maths/Mathahtzee/GamePlayBackgroundSecondarySmall.webp",
			GAME_PLAY_BOARD: baseURL + "Maths/Mathahtzee/GamePlayBoard.webp",
			HOME: baseURL + "Maths/Mathahtzee/HomeButton.webp",
			HOW_TO_PLAY: baseURL + "Maths/Mathahtzee/HowToPlay.webp",
			LETS_PLAY: baseURL + "Maths/Mathahtzee/LetsPlay.webp",
			LETS_PLAY_BACKGROUND: baseURL + "Maths/Mathahtzee/LetsPlayBackground.webp",
			LETS_PLAY_BACKGROUND_COMPRESSED: baseURL + "Maths/Mathahtzee/LetsPlayBackgroundSmall.webp",
			MATHAHTZEE_LARGE: baseURL + "Maths/Mathahtzee/MathahtzeeLarge.webp",
			MATHAHTZEE_SMALL: baseURL + "Maths/Mathahtzee/MathahtzeeSmall.webp",
			MENU_BOARD: baseURL + "Maths/Mathahtzee/MenuBoard.webp",
			NO_VOLUME: baseURL + "Maths/Mathahtzee/NoVolumeImage.webp",
			OPTION: baseURL + "Maths/Mathahtzee/OptionImage.webp",
			PLAY_WITH_COMPUTER: baseURL + "Maths/Mathahtzee/PlayWithComputer.webp",
			PLAY_WITH_FRIEND: baseURL + "Maths/Mathahtzee/PlayWithFriends.webp",
			QUESTION_MODAL: baseURL + "Maths/Mathahtzee/QuestionsModalBackground.webp",
			RED_DICE_FIVE: baseURL + "Maths/Mathahtzee/RedDiceFive.webp",
			RED_DICE_FOUR: baseURL + "Maths/Mathahtzee/RedDiceFour.webp",
			RED_DICE_ONE: baseURL + "Maths/Mathahtzee/RedDiceOne.webp",
			RED_DICE_SIX: baseURL + "Maths/Mathahtzee/RedDiceSix.webp",
			RED_DICE_THREE: baseURL + "Maths/Mathahtzee/RedDiceThree.webp",
			RED_DICE_TWO: baseURL + "Maths/Mathahtzee/RedDiceTwo.webp",
			RED_SCORE_BACKGROUND: baseURL + "Maths/Mathahtzee/RedScoreBackground.webp",
			RED_USER_BACKGROUND: baseURL + "Maths/Mathahtzee/RedUserBackground.webp",
			YOU_WIN: baseURL + "Maths/Mathahtzee/YouWin.webp",
		},
	},
	ECONOMICS: {
		WHITE_LOGO: baseURL + "Economics/economicsLogo.png",
		COMMON_BACKGROUND: baseURL + "Economics/EconomicsBackground.webp",
		COMPRESSED_COMMON_BACKGROUND: baseURL + "Economics/EconomicsBackgroundSmall.webp",
		RAPID_FIRE_CARD: baseURL + "Economics/RapidFireCard.webp",
		PURSUIT_CARD: baseURL + "Economics/PursuitCard.webp",
		RAPID_FIRE_TOP_BAR_TILE: baseURL + "Economics/topbar.png",
		TOP_BAR_TILE: baseURL + "Economics/EconomicsRapidFireProfileBackground.png",
		LEADERBOARD: baseURL + "Economics/Scoreboard.webp",
		RAPID_FIRE: {
			BETTER_LUCK_BACKGROUND: baseURL + "Economics/RapidFire/BetterLuckBoard.webp",
			CONGRATULATIONS_BACKGROUND: baseURL + "Economics/RapidFire/CongratulationsBoard.webp",
			CORRECT_OPTION: baseURL + "Economics/RapidFire/CorrectOption.webp",
			FIRE_BALL: baseURL + "Economics/RapidFire/FireBall.webp",
			BACKGROUND: baseURL + "Economics/RapidFire/GameplayBackground.webp",
			BACKGROUND_COMPRESSED: baseURL + "Economics/RapidFire/GameplayBackgroundSmall.webp",
			HOME: baseURL + "Economics/RapidFire/Home.png",
			HOW_TO_PLAY: baseURL + "Economics/RapidFire/HowToPlayBoard.webp",
			HOW_TO_PLAY_BUTTON: baseURL + "Economics/RapidFire/HowToPlayButton.png",
			INCORRECT_OPTION: baseURL + "Economics/RapidFire/InCorrectOption.webp",
			LETS_PLAY_BUTTON: baseURL + "Economics/RapidFire/LetsPlayButton.png",
			OPTION: baseURL + "Economics/RapidFire/Option.webp",
			QUESTION_MODAL: baseURL + "Economics/RapidFire/QuestionBoard.webp",
			REPLAY: baseURL + "Economics/RapidFire/ReplayButton.png",
			SCORE: baseURL + "Economics/RapidFire/Score.png",
			WRAPPED_UP: baseURL + "Economics/RapidFire/WrappedUp.png",
		},
		PURSUIT: {
			BOY: baseURL + "Economics/Pursuit/characterBoy.png",
			GIRL: baseURL + "Economics/Pursuit/charcterGirl.png",
			CHASER: baseURL + "Economics/Pursuit/chaser.png",
			CHASER_BACKGROUND: baseURL + "Economics/Pursuit/chaserRound.webp",
			COMPRESSED_CHASER_BACKGROUND: baseURL + "Economics/Pursuit/chaserRoundSmall.webp",
			GIRL_TWO: baseURL + "Economics/Pursuit/girl.png",
			AVATAR: baseURL + "Economics/Pursuit/girlTwo.png",
			GIRL_THREE: baseURL + "Economics/Pursuit/girlWithBook.png",
			HOW_TO_PLAY: baseURL + "Economics/Pursuit/HowToPlay.png",
			BACKGROUND: baseURL + "Economics/Pursuit/PursuitBackground.webp",
			BACKGROUND_COMPRESSED: baseURL + "Economics/Pursuit/PursuitBackgroundSmall.webp",
			GAME_BACKGROUND: baseURL + "Economics/Pursuit/PursuitGame.webp",
			GAME_BACKGROUND_COMPRESSED: baseURL + "Economics/Pursuit/PursuitGameSmall.webp",
			LOGO: baseURL + "Economics/Pursuit/PursuitLogo.png",
			LOGO_TWO: baseURL + "Economics/Pursuit/pursuitLogo.webp",
			TIMER: baseURL + "Economics/Pursuit/timer.png",
		},
	},
	FOREIGN: {
		WHITE_LOGO: baseURL + "ForeignLanguage/foreignlanguagelogo.png",
		COMMON_BACKGROUND: baseURL + "ForeignLanguage/backgroundImage.webp",
		COMPRESSED_COMMON_BACKGROUND: baseURL + "ForeignLanguage/backgroundImageCompressed.webp",
		HOW_TO_PLAY: baseURL + "ForeignLanguage/howToPlay.png",
		RAPID_FIRE_CARD: baseURL + "ForeignLanguage/rapidfire.png",
		QUIZ_CARD: baseURL + "ForeignLanguage/QuizGameIcon.png",
		TOP_BAR_TILE: baseURL + "ForeignLanguage/topbarprofile.png",
		LEADERBOARD: baseURL + "ForeignLanguage/topBarList.png",
		RAPID_FIRE: {
			BETTER_LUCK_BACKGROUND: baseURL + "ForeignLanguage/RapidFire/BetterLuckBoard.png",
			CONGRATULATIONS_BACKGROUND: baseURL + "ForeignLanguage/RapidFire/CongratulationsBoard.png",
			HOME: baseURL + "ForeignLanguage/RapidFire/Home.png",
			QUESTION_MODAL: baseURL + "ForeignLanguage/RapidFire/questionModel.png",
			REPLAY_GREEN: baseURL + "ForeignLanguage/RapidFire/ReplayButtonGreen.png",
			REPLAY_RED: baseURL + "ForeignLanguage/RapidFire/ReplayButtonRed.png",
		},
		QUIZ: {
			BETTER_LUCK_BACKGROUND: baseURL + "ForeignLanguage/Quiz/BetterLuckNextTimeBg.webp",
			CONGRATULATIONS_BACKGROUND: baseURL + "ForeignLanguage/Quiz/Congrats-modal.png",
			HOW_TO_PLAY: baseURL + "ForeignLanguage/Quiz/How-To-Play.png",
			DID_YOU_KNOW: baseURL + "ForeignLanguage/Quiz/Did_You_Know.png",
			DAILY_DOUBLE_BACKGROUND: baseURL + "ForeignLanguage/Quiz/Double-Up.png",
			MENU_ICON: baseURL + "ForeignLanguage/Quiz/Menu_Icon.png",
			OPTION: baseURL + "ForeignLanguage/Quiz/Options.png",
			PLAY_BUTTON: baseURL + "ForeignLanguage/Quiz/Play-BorderLess-Btn.png",
			QUESTION_BORDER: baseURL + "ForeignLanguage/Quiz/Question_board_1.png",
			QUESTION_BORDER_COMPRESSED: baseURL + "ForeignLanguage/Quiz/questionBorder.webp",
			QUIZ_ANSWER: baseURL + "ForeignLanguage/Quiz/QuizAnswerBg.png",
			HEADING: baseURL + "ForeignLanguage/Quiz/Quizardy_heading.png",
			GAME_ICON: baseURL + "ForeignLanguage/Quiz/quiz_game_icon.png",
			GAME_ICON_TINY: baseURL + "ForeignLanguage/Quiz/QuizIcon.png",
			QUIZ_QUESTION_BACKGROUND: baseURL + "ForeignLanguage/Quiz/Quiz_Question_Bg.png",
			QUIZ_QUESTION_BOX: baseURL + "ForeignLanguage/Quiz/Quiz_Question_Box.png",
			QUIZ_QUESTION_BACKGROUND_COMPRESSED: baseURL + "ForeignLanguage/Quiz/QuizQuestionCompressedBg.png",
			START_BACKGROUND: baseURL + "ForeignLanguage/Quiz/quiz_start_bg.png",
			START_BACKGROUND_COMPRESSED: baseURL + "ForeignLanguage/Quiz/QuizStartCompressedBg.png",
			SCORE: baseURL + "ForeignLanguage/Quiz/Score-board.png",
			SELECTED_OPTION: baseURL + "ForeignLanguage/Quiz/Selected-Option.png",
			STANDING_DESK: baseURL + "ForeignLanguage/Quiz/Standing-desk.png",
			WAGER_NOW: baseURL + "ForeignLanguage/Quiz/Wager-Now.png",
		},
	},
	LANDING_PAGE: {
		AVATAR: baseURL + "GlobalLandingPage/Avatar.png",
		BASIC_PLAN_A: baseURL + "GlobalLandingPage/BasicPlanSticker1.png",
		BASIC_PLAN_B: baseURL + "GlobalLandingPage/BasicPlanSticker2.png",
		BASIC_PLAN_C: baseURL + "GlobalLandingPage/BasicPlanSticker3.png",
		BASIC_PLAN_D: baseURL + "GlobalLandingPage/BasicPlanSticker4.png",
		BOARD: baseURL + "GlobalLandingPage/Board.webp",
		BOOK_COVER: baseURL + "GlobalLandingPage/bookCover1.png",
		BOOK_SPIRAL: baseURL + "GlobalLandingPage/bookSpiral.png",
		CIVIC_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/CivicLeftSidePage.png",
		CIVIC_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/CivicRightSidePage.png",
		CIVIC_NOTE: baseURL + "GlobalLandingPage/civicNote.png",
		CIVIC_STICKER: baseURL + "GlobalLandingPage/CivicsSticker.png",
		CONNECT4: baseURL + "GlobalLandingPage/connect-4.png",
		CONSTITUTION: baseURL + "GlobalLandingPage/constitution.webp",
		CROSSWORD: baseURL + "GlobalLandingPage/crossword.png",
		DIRECTION_BUTTON: baseURL + "GlobalLandingPage/DirectionButton.png",
		ECONOMICS_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/EconomicsLeftSidePage.png",
		ECONOMICS_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/EconomicsRightSidePage.png",
		ECONOMICS_NOTE: baseURL + "GlobalLandingPage/economicsNote.png",
		ECONOMICS_STICKER: baseURL + "GlobalLandingPage/EconomicsSticker.png",
		ENGLISH_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/EnglishLeftSidePage.png",
		ENGLISH_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/EnglishRightSidePage.png",
		ENGLISH_NOTE: baseURL + "GlobalLandingPage/englishNote.png",
		ENGLISH_STICKER: baseURL + "GlobalLandingPage/EnglishSticker.png",
		EXECUTIVE: baseURL + "GlobalLandingPage/executiveBranch.png",
		FACEBOOK_ICON: baseURL + "GlobalLandingPage/facebook.png",
		FINAL: baseURL + "GlobalLandingPage/final.png",
		FIRE_BALL: baseURL + "GlobalLandingPage/fireBall.png",
		FOREIGN_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/ForeignLeftSidePage.png",
		FOREIGN_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/ForeignRightSidePage.png",
		FOREIGN_NOTE: baseURL + "GlobalLandingPage/foreignNote.png",
		FOREIGN_STICKER: baseURL + "GlobalLandingPage/ForeignLanguageSticker.png",
		HISTORY_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/HistoryLeftSidePage.png",
		HISTORY_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/HistoryRightSidePage.png",
		HISTORY_NOTE: baseURL + "GlobalLandingPage/historyNote.png",
		HISTORY_STICKER: baseURL + "GlobalLandingPage/HistorySticker.png",
		HISTORY_RAPID_FIRE: baseURL + "GlobalLandingPage/HistoryRapidFire.png",
		INSTAGRAM_ICON: baseURL + "GlobalLandingPage/instagram.png",
		JUDICIAL: baseURL + "GlobalLandingPage/judicialBranch.webp",
		PAGE_BACKGROUND: baseURL + "GlobalLandingPage/landingPage.webp",
		PAGE_BACKGROUND_COMPRESSED: baseURL + "GlobalLandingPage/landingPageSmall.webp",
		LEFT_ARROW: baseURL + "GlobalLandingPage/LeftSideArrow.png",
		LEGISLATIVE: baseURL + "GlobalLandingPage/legislativeBranch.png",
		LOOSE_LINK: baseURL + "GlobalLandingPage/looseLink.png",
		MATHAHTZEE: baseURL + "GlobalLandingPage/mathahtzee.png",
		MATH_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/MathLeftSidePage.png",
		MATH_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/MathRightSidePage.png",
		MATH_NOTE: baseURL + "GlobalLandingPage/mathNote.png",
		MATH_STICKER: baseURL + "GlobalLandingPage/MathSticker.png",
		BOOK_BACKGROUND: baseURL + "GlobalLandingPage/ModalBackground.png",
		OPEN_BOOK_SPIRAL: baseURL + "GlobalLandingPage/OpenBookSpiral.png",
		PAYMENT_FAIL: baseURL + "GlobalLandingPage/PaymentFailureSticker.png",
		PAYMENT_SUCCESS: baseURL + "GlobalLandingPage/PaymentSuccessfulSticker.webp",
		PERIODIC_TABLE: baseURL + "GlobalLandingPage/periodicTable.png",
		PLAN: baseURL + "GlobalLandingPage/Plans.webp",
		PLAN_SELECTED: baseURL + "GlobalLandingPage/PlanSelected.webp",
		PREMIUM_PLAN_A: baseURL + "GlobalLandingPage/PremiumPlanSticker1.png",
		PREMIUM_PLAN_B: baseURL + "GlobalLandingPage/PremiumPlanSticker2.png",
		PREMIUM_PLAN_C: baseURL + "GlobalLandingPage/PremiumPlanSticker3.png",
		PREMIUM_PLAN_D: baseURL + "GlobalLandingPage/PremiumPlanSticker4.png",
		PRESENT_OR_PAST: baseURL + "GlobalLandingPage/presentOrPast.png",
		PROFESSIONAL_PLAN_A: baseURL + "GlobalLandingPage/ProfessionalPlanSticker1.png",
		PROFESSIONAL_PLAN_B: baseURL + "GlobalLandingPage/ProfessionalPlanSticker2.png",
		PROFESSIONAL_PLAN_C: baseURL + "GlobalLandingPage/ProfessionalPlanSticker3.png",
		PROFESSIONAL_PLAN_D: baseURL + "GlobalLandingPage/ProfessionalPlanSticker4.png",
		PURSUIT: baseURL + "GlobalLandingPage/pursuit.png",
		QUIZARDY_FOREIGN: baseURL + "GlobalLandingPage/quizardyForeign.png",
		QUIZARDY_CIVICS: baseURL + "GlobalLandingPage/quizzardy.png",
		ECONOMICS_RAPID_FIRE: baseURL + "GlobalLandingPage/rapidFireEconomics.png",
		ENGLISH_RAPID_FIRE: baseURL + "GlobalLandingPage/rapidFireEnglish.png",
		FOREIGN_RAPID_FIRE: baseURL + "GlobalLandingPage/rapidFireForeign.png",
		SCIENCE_RAPID_FIRE: baseURL + "GlobalLandingPage/rapidFireScience.png",
		RIGHT_ARROW: baseURL + "GlobalLandingPage/RightSideArrow.png",
		SCIENCE_LEFT_SIDE_PAGE: baseURL + "GlobalLandingPage/ScienceLeftSidePage.png",
		SCIENCE_RIGHT_SIDE_PAGE: baseURL + "GlobalLandingPage/ScienceRightSidePage.png",
		SCIENCE_NOTE: baseURL + "GlobalLandingPage/scienceNote.png",
		SCIENCE_STICKER: baseURL + "GlobalLandingPage/ScienceSticker.png",
		SELECT_PLAN_BOARD: baseURL + "GlobalLandingPage/SelectPlanBoard.png",
		SUMMARY_NOTE: baseURL + "GlobalLandingPage/SummaryNote.png",
		TEACHER_BACKGROUND: baseURL + "GlobalLandingPage/TeacherLoginPageLayer.webp",
		TEACHER_BACKGROUND_COMPRESSED: baseURL + "GlobalLandingPage/TeacherLoginPageLayerSmall.webp",
		TRY_AGAIN: baseURL + "GlobalLandingPage/TryAgain.png",
		TWITTER_ICON: baseURL + "GlobalLandingPage/twitter.png",
		YOUTUBE_ICON: baseURL + "GlobalLandingPage/youtube.png",
	},
	DASHBOARD: {
		CIVICS_CARD: baseURL + "Background/civics.png",
		CIVICS_BACKGROUND: baseURL + "Background/CivicsBackground.webp",
		CIVICS_BACKGROUND_COMPRESSED: baseURL + "Background/CivicsBackgroundSmall.webp",
		HISTORY_CARD: baseURL + "Background/history.png",
		HISTORY_BACKGROUND: baseURL + "Background/HistoryBackground.webp",
		HISTORY_BACKGROUND_COMPRESSED: baseURL + "Background/HistoryBackgroundSmall.webp",
		HISTORY_SIDE_LOGO: baseURL + "Background/historyIcon.png",
		ENGLISH_CARD: baseURL + "Background/english.png",
		ENGLISH_BACKGROUND: baseURL + "Background/EnglishBackground.webp",
		ENGLISH_BACKGROUND_COMPRESSED: baseURL + "Background/EnglishBackgroundSmall.webp",
		ENGLISH_SIDE_LOGO: baseURL + "Background/englishIcon.png",
		SCIENCE_CARD: baseURL + "Background/science.png",
		SCIENCE_BACKGROUND: baseURL + "Background/ScienceBackground.webp",
		SCIENCE_BACKGROUND_COMPRESSED: baseURL + "Background/ScienceBackgroundSmall.webp",
		SCIENCE_SIDE_LOGO: baseURL + "Background/scienceIcon.png",
		MATH_CARD: baseURL + "Background/math.png",
		MATH_BACKGROUND: baseURL + "Background/MathsBackground.webp",
		MATH_BACKGROUND_COMPRESSED: baseURL + "Background/MathsBackgroundSmall.webp",
		MATH_SIDE_LOGO: baseURL + "Background/mathIcon.png",
		ECONOMICS_CARD: baseURL + "Background/economic.png",
		ECONOMICS_BACKGROUND: baseURL + "Background/EconomicsBackground.webp",
		ECONOMICS_BACKGROUND_COMPRESSED: baseURL + "Background/EconomicsBackgroundSmall.webp",
		ECONOMICS_SIDE_LOGO: baseURL + "Background/economicIcon.png",
		FOREIGN_CARD: baseURL + "Background/foreign.png",
		FOREIGN_BACKGROUND: baseURL + "Background/ForeignBackground.webp",
		FOREIGN_BACKGROUND_COMPRESSED: baseURL + "Background/ForeignBackgroundSmall.webp",
		FOREIGN_SIDE_LOGO: baseURL + "Background/foreignIcon.png",
		LEFT_SIDE_ARROW: baseURL + "Background/leftSideArrow.png",
		RIGHT_SIDE_ARROW: baseURL + "Background/rightSideArrow.png",
		SUBJECT_MASTER_LOGO_BLACK: baseURL + "Background/subject_master_logo.svg",
		SUBJECT_MASTER_LOGO_WHITE: baseURL + "Background/subject_master_logo_white.png",
		WELCOME_LOGO: baseURL + "Background/WelcomeLogo.webp",
		BILL_BECOMES_LAW_IMAGE: baseURL + "Background/bill-becomes-law.png",
		CIVIC_QUIZ_IMAGE: baseURL + "Background/civic-quiz.png",
		CONSTITUTION_IMAGE: baseURL + "Background/constitution.png",
		FINAL_IMAGE: baseURL + "Background/civblitz-final.png",
		JUDICIAL_SPIN: baseURL + "Background/judicial-spin.png",
		HISTORY_RAPID_FIRE_IMAGE: baseURL + "Background/history-rapid-fire.png",
		ENGLISH_RAPID_FIRE_IMAGE: baseURL + "Background/english-rapid-fire.png",
		CROSSWORD_IMAGE: baseURL + "Background/crossword.png",
		PERIODIC_TABLE_IMAGE: baseURL + "Background/periodic-table.png",
		LOOSE_LINK_IMAGE: baseURL + "Background/loose-link.png",
		CONNECT4_IMAGE: baseURL + "Background/connect4.png",
		MATHAHTZEE_IMAGE: baseURL + "Background/mathahtzee.png",
		ECONOMICS_RAPID_FIRE_IMAGE: baseURL + "Background/economics-rapid-fire.png",
		PURSUIT_IMAGE: baseURL + "Background/pursuit.png",
		BILL_OR_NO_BILL_IMAGE: baseURL + "Background/bill-or-no-bill.webp",
		ESCAPE_ROOM_IMAGE: baseURL + "Background/escape-room.png",
		PRESENT_OR_PAST_IMAGE: baseURL + "Background/present-or-past.png",
		SCIENCE_RAPID_FIRE_IMAGE: baseURL + "Background/science-rapiod-fire.png",
		FOREIGN_LANGUAGE_RAPID_FIRE_IMAGE: baseURL + "Background/fl-rapid-fire.png",
		FOREIGN_LANGUAGE_QUIZARDY_IMAGE: baseURL + "Background/fl-quizardy.png",
		CIVICS_MODAL_IMAGE: baseURL + "Background/civic-modal-image.webp",
		ECONOMICS_MODAL_IMAGE: baseURL + "Background/economics-modal-image.webp",
		ENGLISH_MODAL_IMAGE: baseURL + "Background/english-modal-image.webp",
		FOREIGN_MODAL_IMAGE: baseURL + "Background/fl-modal-image.webp",
		HISTORY_MODAL_IMAGE: baseURL + "Background/history-modal-image.webp",
		MATH_MODAL_IMAGE: baseURL + "Background/math-modal-image.webp",
		SCIENCE_MODAL_IMAGE: baseURL + "Background/science-modal-image.webp",
		TOP_ROUND_IMAGE: baseURL + "Background/top-round-image.png",
	},
	COMMONS: {
		WHITE_ARROW: baseURL + "Commons/arrow.png",
		BLACK_ARROW: baseURL + "Commons/arrow_black.png",
		DOWN_ARROW: baseURL + "Commons/down.svg",
		FIRE_BALL: baseURL + "Commons/Fireball.png",
		HOME: baseURL + "Commons/Home.png",
		SETTINGS_ICON: baseURL + "Commons/Settings.png",
	},
	PROFILE: {
		BOY: baseURL + "Profile/boy-1.png",
		AVATAR: {
			AVATAR_1: baseURL + "Profile/Avatar/avatar1.png",
			AVATAR_2: baseURL + "Profile/Avatar/avatar2.png",
			AVATAR_3: baseURL + "Profile/Avatar/avatar3.png",
			AVATAR_4: baseURL + "Profile/Avatar/avatar4.png",
			AVATAR_5: baseURL + "Profile/Avatar/avatar5.png",
			AVATAR_6: baseURL + "Profile/Avatar/avatar6.png",
		},
	},
	SIDEBAR: {
		CIVIC_OPTION_BACKGROUND: baseURL + "Sidebar/Civics/OptionBackground.png",
		CIVIC_PROFILE_BACKGROUND: baseURL + "Sidebar/Civics/ProfileBackground.png",
		CIVIC_SIDEBAR_BACKGROUND: baseURL + "Sidebar/Civics/SidebarBackground.png",
		ECONOMICS_OPTION_BACKGROUND: baseURL + "Sidebar/Economics/OptionBackground.png",
		ECONOMICS_PROFILE_BACKGROUND: baseURL + "Sidebar/Economics/ProfileBackground.png",
		ECONOMICS_SIDEBAR_BACKGROUND: baseURL + "Sidebar/Economics/SidebarBackground.png",
		ENGLISH_OPTION_BACKGROUND: baseURL + "Sidebar/English/OptionBackground.png",
		ENGLISH_PROFILE_BACKGROUND: baseURL + "Sidebar/English/ProfileBackground.png",
		ENGLISH_SIDEBAR_BACKGROUND: baseURL + "Sidebar/English/SidebarBackground.png",
		FOREIGN_OPTION_BACKGROUND: baseURL + "Sidebar/Foreign/OptionBackground.png",
		FOREIGN_PROFILE_BACKGROUND: baseURL + "Sidebar/Foreign/ProfileBackground.png",
		FOREIGN_SIDEBAR_BACKGROUND: baseURL + "Sidebar/Foreign/SidebarBackground.png",
		HISTORY_OPTION_BACKGROUND: baseURL + "Sidebar/History/OptionBackground.png",
		HISTORY_PROFILE_BACKGROUND: baseURL + "Sidebar/History/ProfileBackground.png",
		HISTORY_SIDEBAR_BACKGROUND: baseURL + "Sidebar/History/SidebarBackground.png",
		MATH_OPTION_BACKGROUND: baseURL + "Sidebar/Maths/OptionBackground.webp",
		MATH_PROFILE_BACKGROUND: baseURL + "Sidebar/Maths/ProfileBackground.webp",
		MATH_SIDEBAR_BACKGROUND: baseURL + "Sidebar/Maths/SidebarBackground.webp",
		SCIENCE_OPTION_BACKGROUND: baseURL + "Sidebar/Science/OptionBackground.png",
		SCIENCE_PROFILE_BACKGROUND: baseURL + "Sidebar/Science/ProfileBackground.png",
		SCIENCE_SIDEBAR_BACKGROUND: baseURL + "Sidebar/Science/SidebarBackground.png",
		LESS_ARROW: baseURL + "Sidebar/less.svg",
		MORE_ARROW: baseURL + "Sidebar/more.svg",
	},
};

export const VIDEOS = {
	LANDING_PAGE: {
		SNEAK_PEEK: baseURLVideo + "SubjectsMasterVideoWithsound.mp4",
	},
};

export const SUBJECTS_DATA = [
	{
		id: 1,
		name: "SCIENCE",
		title: "Science",
		image: ScienceRoundImage,
		shadowColor: "#89CFF0",
		star: {
			image: Star,
			top: "-20%",
			right: 0,
		},
		feather: {
			image: BlueFeather,
			right: "10%",
			bottom: "40%",
		},
		ball: {
			image: BlueBall,
			right: "-10%",
			bottom: "10%",
		},
		ribbon: {
			image: BlueRibbon,
			right: "10%",
			top: "62%",
		},
		description:
			"Science Master seeks to invigorate science to show how science impacts us on a day to day basis. Student involvement in studying living things, whether it’s the earth’s make-up, fundamental units of life or identifying substances, is vital to science growth. Appealing to the students curiosity by encouraging objective observation and experimentation will create an exciting atmosphere of learning and can strengthen the students will to master this subject.",
	},
	{
		id: 2,
		name: "MATH",
		title: "Math",
		image: MathRoundImage,
		shadowColor: "#EE4B2B",
		star: {
			image: Star,
			top: "-20%",
			right: 0,
		},
		feather: {
			image: YellowFeather,
			right: "8%",
			bottom: "10%",
		},
		ball: {
			image: GreenBall,
			right: "70%",
			bottom: "80%",
		},
		ribbon: {
			image: RedRibbon,
			right: 0,
			top: "60%",
		},
		description:
			"Math Master strives to help students overcome their fear of numbers and concepts. Studying numbers interrelation, measurements, combinations, and operations can be a challenge for some. We try to ease the anxiety some may feel by providing a themed way of learning these concepts through gaming. We seek to inspire students to embrace the challenge by making it enjoyable to learn and as simple as possible to understand.",
	},
	{
		id: 3,
		name: "HISTORY",
		title: "History",
		image: HistoryRoundImage,
		shadowColor: "#5F9EA0",
		star: {
			image: Star,
			top: "-20%",
			right: 0,
		},
		feather: {
			image: BlueFeather,
			right: "-20%",
			bottom: 0,
		},
		ribbon: {
			image: GreenRibbon,
			right: "-20%",
			top: "20%",
		},
		description:
			"History Master wants to revitalize the historical reference of American history in such a way, that it will dispel censuring, by demanding sensibility and recognition of our history no matter how obscure. The teaching of history provides the knowledge of how we started, assess the events of how we’ve changed and foster collaboration on how we move forward as a society, in the hopes that understanding our past will guide our future.",
	},
	{
		id: 4,
		name: "ECONOMICS",
		title: "Economics",
		image: EconomicsRoundImage,
		shadowColor: "#4682B4",
		star: {
			image: Star,
			top: "-20%",
			right: 0,
		},
		feather: {
			image: BlueFeather,
			right: 0,
			bottom: "40%",
		},
		ball: {
			image: BlueBall,
			right: 0,
			bottom: 0,
		},
		ribbon: {
			image: BlueRibbon,
			right: "24%",
			top: "60%",
		},
		description:
			"Economics Master is here to prepare students during their transition to becoming young adults, by providing the economic knowledge of how to employ scarce resources, manage finances as it relates to budgeting, investing and savings via macroeconomics. Supply, demand and market price via microeconomics. Fiscal policies as it relates to tax and spend and introducing them to the global economy as it also effects our choices. Presenting these topics in the most stimulating way will allow students to own their financial destiny.",
	},
	{
		id: 5,
		name: "ENGLISH",
		title: "English",
		image: EnglishRoundImage,
		shadowColor: "#FFAC1C",
		star: {
			image: LeftStar,
			top: "-10%",
			right: "80%",
		},
		feather: {
			image: YellowFeather,
			right: "-26%",
			bottom: "50%",
		},
		ball: {
			image: GreenBall,
			right: "10%",
			bottom: 0,
		},
		description:
			"English Master understands the importance of an extensive vocabulary and how it benefits an individual’s comprehension and his/her oral and written communication. Broad reading delivers thoughtful evaluation, promotes critical thinking and compels understanding. An extensive vocabulary drives high quality communication and a great communicator acknowledges the need to read.",
	},
	{
		id: 6,
		name: "FOREIGN_LANGUAGE",
		title: "Foreign Language",
		image: ForeignRoundImage,
		shadowColor: "#5F9EA0",
		star: {
			image: Star,
			top: "-20%",
			right: 0,
		},
		feather: {
			image: YellowFeather,
			right: "8%",
			bottom: "10%",
		},
		ball: {
			image: GreenBall,
			right: "70%",
			bottom: "80%",
		},
		ribbon: {
			image: RedRibbon,
			right: 0,
			top: "60%",
		},
		description:
			"Foreign Language Master encourages the study of multiple languages. Being able to communicate with a variety of cultures will only enhance a student’s ability to become a dynamic voice in the conversation. As our world continues to become more interdependent, the ability to speak a language that is not widely spoken in the country you reside, will enhance the students understanding of their own culture, provide a competitive edge and create a confidence that will endure.",
	},
];

export const GAMES_DATA = {
	CIVICS: [
		{
			id: 1,
			name: "Civics",
			image: IMAGES.DASHBOARD.CIVICS_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the Civics module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Bill Or No Bill",
			image: IMAGES.DASHBOARD.BILL_OR_NO_BILL_IMAGE,
			description:
				"Test your students knowledge of the legislative branch by providing 26 questions that you’ve addressed with in-class instruction.  The leader board will show who’s standing out in your class.",
		},
		{
			id: 3,
			name: "How To Bill Become A Law",
			image: IMAGES.DASHBOARD.BILL_BECOMES_LAW_IMAGE,
			description: "Do your students know the process of How A Bill Becomes A Law? If not this game will help them learn the steps it takes to pass legislation.",
		},
		{
			id: 4,
			name: "Executive Branch",
			image: IMAGES.DASHBOARD.ESCAPE_ROOM_IMAGE,
			description: "Students will need to challenge themselves and think outside the box in order to complete this Escape Room! Does your students have what it takes?",
		},
		{
			id: 5,
			name: "Judicial Branch",
			image: IMAGES.DASHBOARD.JUDICIAL_SPIN,
			description: "Have your students take a spin around the courts. They will earn spins by answering the judicial questions you provide and then they will press their luck on the board.",
		},
		{
			id: 6,
			name: "Constitution",
			image: IMAGES.DASHBOARD.CONSTITUTION_IMAGE,
			description: "How well does your students know the constitution? This game will test their knowledge in a rapid fire way.",
		},
		{
			id: 7,
			name: "Quiz",
			image: IMAGES.DASHBOARD.CIVIC_QUIZ_IMAGE,
			description: "This quiz game will challenge your students in each category of the Legislative branch. This is a great game to test the overall legislative knowledge of your students.",
		},
		{
			id: 8,
			name: "Final",
			image: IMAGES.DASHBOARD.FINAL_IMAGE,
			description:
				"The Final stage of the Civics module. A cumulative assessment of the legislative branch. Can your students pass this final game which again is based on the questions you the educator provides?",
		},
	],
	HISTORY: [
		{
			id: 1,
			name: "History",
			image: IMAGES.DASHBOARD.HISTORY_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the History module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Rapid Fire",
			image: IMAGES.DASHBOARD.HISTORY_RAPID_FIRE_IMAGE,
			description: "Make History fun to learn with this rapid fire way of learning? Test how well your students has retained the knowledge of History taught in your class.",
		},
		{
			id: 3,
			name: "Present Or Past",
			image: IMAGES.DASHBOARD.PRESENT_OR_PAST_IMAGE,
			description:
				"Test your students knowledge of History by providing 26 questions that you’ve addressed with in-class instruction. The leader board will show who’s standing out in your class.",
		},
	],
	ENGLISH: [
		{
			id: 1,
			name: "English",
			image: IMAGES.DASHBOARD.ENGLISH_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the English module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Rapid Fire",
			image: IMAGES.DASHBOARD.ENGLISH_RAPID_FIRE_IMAGE,
			description: "How well has your students memorized the definitions for their vocabulary words?  This game will test their knowledge in a rapid fire way.",
		},
		{
			id: 3,
			name: "Crossword",
			image: IMAGES.DASHBOARD.CROSSWORD_IMAGE,
			description: "Have your students solve the crossword puzzle using words that you the educator has provided definitions for.  This game can be challenging.",
		},
	],
	SCIENCE: [
		{
			id: 1,
			name: "Science",
			image: IMAGES.DASHBOARD.SCIENCE_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the Science module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Rapid Fire",
			image: IMAGES.DASHBOARD.SCIENCE_RAPID_FIRE_IMAGE,
			description: "How well does your students know the Science?  This game will test their knowledge of any Science questions the educator enters in a rapid fire way.",
		},
		{
			id: 3,
			name: "Periodic Table",
			image: IMAGES.DASHBOARD.PERIODIC_TABLE_IMAGE,
			description: "This interactive periodic table provides a fun and exciting way for Students to learn and retain all the elements of the periodic table. Check it out.",
		},
		{
			id: 4,
			name: "Loose Link",
			image: IMAGES.DASHBOARD.LOOSE_LINK_IMAGE,
			description: "Assign six (6) students to play this engaging Loose Link game in class or outside of class and let’s find out who will be standing in the end.",
		},
	],
	MATH: [
		{
			id: 1,
			name: "Math",
			image: IMAGES.DASHBOARD.MATH_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the Math module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Connect4",
			image: IMAGES.DASHBOARD.CONNECT4_IMAGE,
			description: "Connect 4 can be played with another student in the same class or the computer. Test your students math knowledge in a fun and competitive way.",
		},
		{
			id: 3,
			name: "Mathahtzee",
			image: IMAGES.DASHBOARD.MATHAHTZEE_IMAGE,
			description: "Students try their luck at rolling a MATHAHTZEE! if they can answer your tough math questions correctly. Game can be played with another student or against the computer.",
		},
	],
	ECONOMICS: [
		{
			id: 1,
			name: "Economics",
			image: IMAGES.DASHBOARD.ECONOMICS_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the Economics module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Rapid Fire",
			image: IMAGES.DASHBOARD.ECONOMICS_RAPID_FIRE_IMAGE,
			description: "How well has your students retained the Economics of today?  This game will test their knowledge in a rapid fire way.",
		},
		{
			id: 3,
			name: "Pusuit",
			image: IMAGES.DASHBOARD.PURSUIT_IMAGE,
			description: "Round 1 gather as many points as possible. Round 2 attempt to Out last the Pursuit. Round 3 earn your points. This three (3) stage game can be challenging but motivating.",
		},
	],
	FOREIGN_LANGUAGE: [
		{
			id: 1,
			name: "Foreign Language",
			image: IMAGES.DASHBOARD.FOREIGN_MODAL_IMAGE,
			mainText:
				"Educators will input their own lesson plan into the “Did You Know” section of each game in the Foreign Language module. This lesson plan will be seen by the students when they log into the respective module and game. After reviewing the lesson plan the student will click the “How To Play” button which will provide the student the game instructions. The student will then click the “Let’s Play” button and the game will begin with the questions input by the educator.  The educator will then be able to monitor the students’ progress in each game. There is a leader board per class and students will be vying for that top spot.",
			description: "Click on each dot below to see each game within this module.",
		},
		{
			id: 2,
			name: "Rapid Fire",
			image: IMAGES.DASHBOARD.FOREIGN_LANGUAGE_RAPID_FIRE_IMAGE,
			description:
				"How well does your students know the foreign language you are currently teaching. Whether it’s Spanish, French, Italian or Mandarin? This game will test their knowledge in a rapid fire way.",
		},
		{
			id: 3,
			name: "Quizardy",
			image: IMAGES.DASHBOARD.FOREIGN_LANGUAGE_QUIZARDY_IMAGE,
			description:
				"This quiz game will challenge your students in each category of the Foreign language you are teaching. This is a great game to test the students overall knowledge of the nouns, verbs, numbers, letters, colors and/or greeting.",
		},
	],
};
